import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Box } from 'wui/Box';

import { Column } from './Column';

import classes from './Layout.scss';

type ColumnType = React.ReactElement<React.ComponentProps<typeof Column>>;

interface IProps extends React.ComponentProps<typeof Box> {
  responsive?: boolean;
  children: ColumnType | ColumnType[];
}

export function Layout(props: IProps) {
  const { isCssPerBreakpoint } = useEnvironment();
  const { children, className, responsive, ...rest } = props;

  const multiple = React.Children.count(children) > 1;

  const columns = !multiple
    ? children
    : React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          ...child.props,
          className: cls(
            child.props.className,
            index === 0 ? classes.primary : classes.secondary,
          ),
        }),
      );

  return (
    <Box
      direction={multiple ? 'horizontal' : 'vertical'}
      className={cls(classes.root, className, {
        [classes.multiple]: multiple,
        [classes.responsive]: isCssPerBreakpoint && responsive,
      })}
      {...rest}
    >
      {columns}
    </Box>
  );
}

Layout.displayName = 'Layout';
